import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "honorSociety mb-2" }
const _hoisted_2 = { class: "honorSociety-text mb-0" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "honorSociety mb-0" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, [
      ($props.HonorSociety.length === 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Honor Society:"))
        : _createCommentVNode("", true),
      ($props.HonorSociety.length > 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Honor Societies:"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("span", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.HonorSociety, (honorSociety, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: 'HonorSociety' + index,
          class: "mr-3 mb-3"
        }, [
          _createElementVNode("span", null, [
            _createElementVNode("h5", _hoisted_5, _toDisplayString(honorSociety.title), 1),
            (index != $props.HonorSociety.length - 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, ", "))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128))
    ])
  ]))
}