import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bar" }
const _hoisted_2 = { class: "form-inline" }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("label", {
        class: "my-1 mr-2 subheading",
        for: "inlineFormCustomSelectPref"
      }, "Why are you here?", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.selectedIndex) = $event)),
        class: "custom-select my-1 mr-sm-2",
        id: "inlineFormCustomSelectPref"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.About.options, (option, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: 'callToAction' +  index,
            value: index
          }, _toDisplayString(option), 9, _hoisted_3))
        }), 128))
      ], 512), [
        [_vModelSelect, $data.selectedIndex]
      ]),
      _createElementVNode("a", {
        role: "button",
        class: "btn js-scroll-trigger",
        href: "#contact",
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.updateContact($data.selectedIndex)))
      }, "Submit")
    ])
  ]))
}