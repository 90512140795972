import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "bullets" }
const _hoisted_2 = { class: "subheading mb-3" }
const _hoisted_3 = { class: "fa-ul mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.Bullets, (section, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'Bullet' + index
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(section.heading), 1),
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.bullets, (bullet, bindex) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index + '' + bindex
            }, [
              _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa-li fa fa-check" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(bullet), 1)
            ]))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}