import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/img/profile.jpg'


const _hoisted_1 = {
  class: "navbar navbar-expand-lg navbar-dark bg-primary fixed-top",
  id: "sideNav"
}
const _hoisted_2 = {
  class: "navbar-brand js-scroll-trigger",
  href: "#page-top"
}
const _hoisted_3 = { class: "d-block d-lg-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("nav", { class: "navbar navbar-default" }, null, -1)),
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("a", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString($props.About.firstName) + " " + _toDisplayString($props.About.lastName), 1),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "d-none d-lg-block" }, [
          _createElementVNode("img", {
            class: "img-fluid img-profile rounded-circle mx-auto mb-2",
            src: _imports_0,
            alt: "profile pic"
          })
        ], -1))
      ]),
      _cache[1] || (_cache[1] = _createStaticVNode("<button class=\"navbar-toggler\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#navbarSupportedContent\" aria-controls=\"navbarSupportedContent\" aria-expanded=\"false\" aria-label=\"Toggle navigation\"><span class=\"navbar-toggler-icon\"></span></button><div class=\"collapse navbar-collapse\" id=\"navbarSupportedContent\"><ul class=\"navbar-nav\"><li class=\"nav-item\"><a class=\"nav-link js-scroll-trigger\" href=\"#about\">About</a></li><li class=\"nav-item\"><a class=\"nav-link js-scroll-trigger\" href=\"#experience\">Experience</a></li><li class=\"nav-item\"><a class=\"nav-link js-scroll-trigger\" href=\"#education\">Education</a></li><li class=\"nav-item\"><a class=\"nav-link js-scroll-trigger\" href=\"#tech\">Tech</a></li><li class=\"nav-item\"><a class=\"nav-link js-scroll-trigger\" href=\"#awards\">Awards</a></li><li class=\"nav-item\"><a class=\"nav-link js-scroll-trigger\" href=\"#contact\">Contact</a></li></ul></div>", 2))
    ])
  ]))
}