<template>
  <div class="main-wrapper">
    <AsideComponent :About="config.about" />
    <div class="container-fluid">
      <AboutComponent v-bind:About="config.about" />
      <!-- <CallToAction 
        v-bind:About="config.about"
        v-bind:ContactIndex="contactIndex"
        @updateContact="setContactIndex" /> -->
      <hr class="m-0" />
      <ExperienceComponent v-bind:ExperienceList="config.experience" />
      <hr class="m-0" />
      <EducationComponent v-bind:EducationList="config.education" />
      <hr class="m-0" />
      <SkillsComponent v-bind:Skills="config.skills" />
      <hr class="m-0" />
      <!-- <InterestsComponent v-bind:Interests="config.personal" />
      <hr class="m-0" /> -->
      <!-- <ProjectComponent v-bind:Projects="config.projects" />
      <hr class="m-0" /> -->
      <AwardsComponent v-bind:Awards="config.awards" />
      <hr class="m-0" />
      <ContactComponent 
        v-bind:About="config.about" 
        v-bind:ContactIndex="contactIndex" />
      <AttributionComponent v-bind:Attribution="config.attribution" />
    </div>
  </div>
</template>

<script>

import AsideComponent from './Aside/AsideComponent.vue'
import AboutComponent from './About/AboutComponent.vue'
import ExperienceComponent from './Experience/ExperienceComponent.vue'
import EducationComponent from './Education/EducationComponent.vue'
import SkillsComponent from './Skills/SkillsComponent.vue'
// import InterestsComponent from './Interests/InterestsComponent.vue'
// import ProjectComponent from './Project/ProjectComponent.vue'
import AwardsComponent from './Awards/AwardsComponent.vue'
import CallToAction from './About/CallToAction.vue'
import ContactComponent from './About/ContactComponent.vue'
import AttributionComponent from './Attribution/AttributionComponent.vue'

export default {
  name: 'PageComponent',
  components: {
    AsideComponent,
    AboutComponent,
    ExperienceComponent,
    EducationComponent,
    SkillsComponent,
    // InterestsComponent,
    // ProjectComponent,
    AwardsComponent,
    CallToAction,
    ContactComponent,
    AttributionComponent
  },
  props: {
    config: Object
  },
  data () {
    return {
      contactIndex: 0
    }
  },
  mounted () {
    const $ = window.jquery
    this.$nextTick(() => {
      // our custom jQuery code goes here
      $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
          let target = $(this.hash)
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
          if (target.length) {
            $('html, body').animate({
              scrollTop: (target.offset().top)
            }, 1000, 'easeInOutExpo')
            return false
          }
        }
      })
      // Closes responsive menu when a scroll trigger link is clicked
      $('.js-scroll-trigger').click(function () {
        $('.navbar-collapse').collapse('hide')
      })
      // Activate scrollspy to add active class to navbar items on scroll
      $('body').scrollspy({
        target: '#sideNav'
      })
      $('[data-bs-toggle="tooltip"]').tooltip()
      $(function () {
        $('[data-bs-toggle="popover"]').popover()
        $('.popover-dismiss').popover({
          trigger: 'focus'
        })
      })

    })
  },
  methods: {
    setContactIndex (index) {
      this.contactIndex = index
    }
  }
}
</script>

<style scoped>
.container-fluid {
  padding: 0;
}
</style>
