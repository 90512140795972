import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "resume-section p-3 p-lg-5 d-flex align-items-center",
  id: "awards"
}
const _hoisted_2 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AwardsContent = _resolveComponent("AwardsContent")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "mb-2" }, "Awards", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.Awards, (award, index) => {
        return (_openBlock(), _createElementBlock("ul", {
          class: "fa-ul mb-0",
          key: 'Awards' + index
        }, [
          _createVNode(_component_AwardsContent, { Award: award }, null, 8, ["Award"])
        ]))
      }), 128))
    ])
  ]))
}