import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-subtitle mb-2 text-muted" }
const _hoisted_5 = {
  key: 0,
  class: "bar"
}
const _hoisted_6 = { class: "progress" }
const _hoisted_7 = ["aria-valuenow"]
const _hoisted_8 = { class: "card-text" }
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("i", {
          class: _normalizeClass($props.skill.icon)
        }, null, 2),
        _createTextVNode(" " + _toDisplayString($props.skill.title), 1)
      ]),
      _createElementVNode("h5", _hoisted_4, _toDisplayString($props.skill.subheading), 1),
      ($props.skill.level)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createTextVNode(" Skill Level: " + _toDisplayString($props.skill.level) + " ", 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: "progress-bar progress-bar-striped progress-bar-animated",
                role: "progressbar",
                "aria-valuenow": $props.skill.progress,
                style: _normalizeStyle('width: ' + $props.skill.progress + '%;'),
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }, null, 12, _hoisted_7)
            ])
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.skill.description, (description, dindex) => {
        return (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(description), 1))
      }), 256)),
      ($props.skill.link)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: $props.skill.link,
            target: "_blank",
            class: "card-link btn btn-primary"
          }, " More Info ", 8, _hoisted_9))
        : _createCommentVNode("", true)
    ])
  ]))
}