import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "resume-section p-3 p-lg-5",
  id: "tech"
}
const _hoisted_2 = { class: "wrapper w-100 d-lg-flex d-sm-flex align-items-center" }
const _hoisted_3 = { class: "skill-container pr-2" }
const _hoisted_4 = { class: "skill-groups mn-50 pl-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkillsCard = _resolveComponent("SkillsCard")!
  const _component_SkillsSkillset = _resolveComponent("SkillsSkillset")!
  const _component_SkillsBullets = _resolveComponent("SkillsBullets")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "mb-2" }, "Technical Skills", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SkillsCard, {
          skill: $props.Skills.catalog[$data.activeIndex]
        }, null, 8, ["skill"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.SkillList, (skillset, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'Skill' + index
          }, [
            _createVNode(_component_SkillsSkillset, {
              skillset: skillset,
              activeIndex: $data.activeIndex ? $data.activeIndex : 0,
              onToggleActivated: $options.setActiveIndex
            }, null, 8, ["skillset", "activeIndex", "onToggleActivated"])
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_SkillsBullets, {
      Bullets: $props.Skills.bullets
    }, null, 8, ["Bullets"])
  ]))
}