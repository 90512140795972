import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "gpa" }
const _hoisted_2 = {
  key: 0,
  class: "mr-5"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h4", _hoisted_1, [
    _createElementVNode("div", null, [
      ($props.GPA.cumulative)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Cumulative GPA: " + _toDisplayString($props.GPA.cumulative) + " / " + _toDisplayString($props.GPA.scale), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      ($props.GPA.major)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Major GPA: " + _toDisplayString($props.GPA.major) + " / " + _toDisplayString($props.GPA.scale), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}