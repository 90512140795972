import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bar" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, [
      _cache[0] || (_cache[0] = _createTextVNode(" Template Created from: ")),
      _createElementVNode("a", {
        href: $props.Attribution.templatelink
      }, _toDisplayString($props.Attribution.template), 9, _hoisted_2)
    ]),
    _createElementVNode("p", null, [
      _cache[1] || (_cache[1] = _createTextVNode(" Design Inspired from: ")),
      _createElementVNode("a", {
        href: $props.Attribution.inspirationlink
      }, _toDisplayString($props.Attribution.inspiration), 9, _hoisted_3)
    ])
  ]))
}