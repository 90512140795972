import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "header mb-2" }
const _hoisted_3 = { class: "text-primary award-date" }
const _hoisted_4 = {
  key: 0,
  class: "body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa-li fa fa-trophy" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, [
        _createElementVNode("h3", null, _toDisplayString($props.Award.title), 1)
      ]),
      _createElementVNode("span", _hoisted_3, _toDisplayString($props.Award.date), 1)
    ]),
    ($props.Award.bullets)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.Award.bullets, (bullet, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: 'AwardBullet' + index
            }, _toDisplayString(bullet), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}