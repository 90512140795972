import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "resume-section p-3 p-lg-5 d-flex align-items-center",
  id: "contact"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "w-100" }, [
      _createElementVNode("h2", { class: "mb-2" }, "Contact"),
      _createElementVNode("iframe", {
        src: "https://docs.google.com/forms/d/e/1FAIpQLSdOBkx7WOg6rJZeraZO7R9NkbAw2unEApVq9zt8uta7ve2nJw/viewform?embedded=true",
        width: "640",
        height: "722",
        frameborder: "0",
        marginheight: "0",
        marginwidth: "0"
      }, "Loading…")
    ], -1)
  ])))
}