import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "resume-section p-3 p-lg-5 d-flex justify-content-center",
  id: "experience"
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { class: "experience-body pl-4" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExperienceContent = _resolveComponent("ExperienceContent")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "experience-head" }, [
        _createElementVNode("h2", { class: "mb-2" }, "Experience")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.ExperienceList, (experience, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'ExperienceList' + index
          }, [
            _createVNode(_component_ExperienceContent, {
              experience: experience,
              index: index
            }, null, 8, ["experience", "index"]),
            (index !== $props.ExperienceList.length - 1)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ]))
}