import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "degree-container" }
const _hoisted_2 = {
  key: 0,
  class: "degree-header mb-1 mt-2"
}
const _hoisted_3 = { class: "degree subheading" }
const _hoisted_4 = { class: "expected-date" }
const _hoisted_5 = { class: "degree-body" }
const _hoisted_6 = { class: "detail-attributes mb-2" }
const _hoisted_7 = {
  key: 1,
  class: "m-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EducationGPA = _resolveComponent("EducationGPA")!
  const _component_EducationMajor = _resolveComponent("EducationMajor")!
  const _component_EducationMinor = _resolveComponent("EducationMinor")!
  const _component_EducationCertificate = _resolveComponent("EducationCertificate")!
  const _component_EducationHonorSociety = _resolveComponent("EducationHonorSociety")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.Degrees, (degree, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "degree-divider"
      }, [
        (degree.degree)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, [
                _createElementVNode("span", {
                  class: _normalizeClass(["bubble-minor", { complete: degree.status === 'complete', current: degree.status === 'current' }])
                }, null, 2),
                _createElementVNode("div", null, _toDisplayString(degree.degree), 1)
              ]),
              _createElementVNode("span", _hoisted_4, "Conferred: " + _toDisplayString(degree.conferred), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (degree.gpa)
              ? (_openBlock(), _createBlock(_component_EducationGPA, {
                  key: 0,
                  GPA: degree.gpa
                }, null, 8, ["GPA"]))
              : _createCommentVNode("", true),
            (degree.major)
              ? (_openBlock(), _createBlock(_component_EducationMajor, {
                  key: 1,
                  Major: degree.major
                }, null, 8, ["Major"]))
              : _createCommentVNode("", true),
            (degree.minor)
              ? (_openBlock(), _createBlock(_component_EducationMinor, {
                  key: 2,
                  Minor: degree.minor
                }, null, 8, ["Minor"]))
              : _createCommentVNode("", true),
            (degree.certificate)
              ? (_openBlock(), _createBlock(_component_EducationCertificate, {
                  key: 3,
                  Certificate: degree.certificate
                }, null, 8, ["Certificate"]))
              : _createCommentVNode("", true),
            (degree.honorSociety)
              ? (_openBlock(), _createBlock(_component_EducationHonorSociety, {
                  key: 4,
                  HonorSociety: degree.honorSociety
                }, null, 8, ["HonorSociety"]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(degree.description, (descParagraphh, degreeIdx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "mt-4 mb-4 desc_paragraph",
                key: 'EducationList' + index + 'Description' + degreeIdx
              }, _toDisplayString(descParagraphh), 1))
            }), 128))
          ])
        ]),
        (index !== $props.Degrees.length - 1)
          ? (_openBlock(), _createElementBlock("hr", _hoisted_7))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}