import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import jquery from 'jquery'
require('bootstrap')
require('jquery.easing')

require('bootstrap/dist/css/bootstrap.css')
require('devicons/css/devicons.css')
require('@fortawesome/fontawesome-free/css/all.min.css')
require('./assets/css/resume.css')
require('./assets/js/form-submission-handler.js')


// new Vue({
//   render: (h) => h(App)
// }).$mount('#app')


// @ts-ignore
const app = createApp(App);
app.config.globalProperties.jquery = jquery
app.config.globalProperties.productionTip = false
app.mount("#app")

