<template>
  <span class="honorSociety mb-2">
    <h5 class="honorSociety-text mb-0">
      <span v-if="HonorSociety.length === 1">Honor Society:</span>
      <span v-if="HonorSociety.length > 1">Honor Societies:</span>
    </h5>
    <span>
      <span v-for="(honorSociety, index) in HonorSociety" v-bind:key="'HonorSociety' + index" class="mr-3 mb-3">
        <!-- <a 
          tabindex="0"
          data-trigger="focus"
          role="button" 
          class="btn btn-primary btn-sm"
          data-bs-toggle="popover" 
          :title="honorSociety.title" 
          :data-content="honorSociety.description">
          {{honorSociety.title}}
        </a> -->
        <span>
          <h5 class="honorSociety mb-0">
            {{honorSociety.title}}
          </h5>
          <span v-if="index != HonorSociety.length - 1" >, </span>
        </span>
      </span>
    </span>
  </span>
</template>

<script lang="ts">
export default {
  name: 'EducationHonorSociety',
  props: {
    HonorSociety: Array
  }
}
</script>

<style scoped lang="scss">
.honorSociety {
  display: inline-block;
  font-weight: 500;
  align-content: center;
  flex-wrap: wrap;
}

.honorSociety-text {
  display: inline-block;
  font-weight: 500;
  width: 15%;
  min-width: 75px;
}

a.btn, a.btn:focus {
  cursor: pointer;
  color: white !important;
}

a.btn:hover {
  color: #2196F3 !important;
}
</style>
