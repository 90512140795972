import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "subheading mb-5 d-md-none" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "subheading mb-5 d-none d-md-block" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, _toDisplayString($props.About.address.city) + ", " + _toDisplayString($props.About.address.state) + " " + _toDisplayString($props.About.address.zip) + ", " + _toDisplayString($props.About.address.country), 1),
      ($props.About.tel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("a", {
              href: 'tel:' + $props.About.formattedPhone
            }, _toDisplayString($props.About.phone), 9, _hoisted_3)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("a", {
          href: 'mailto:' + $props.About.email,
          target: "_blank"
        }, _toDisplayString($props.About.email), 9, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createTextVNode(_toDisplayString($props.About.address.city) + ", " + _toDisplayString($props.About.address.state), 1),
      ($props.About.address.zip)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($props.About.address.zip), 1))
        : _createCommentVNode("", true),
      _createTextVNode(", " + _toDisplayString($props.About.address.country) + " · ", 1),
      ($props.About.tel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("a", {
              href: 'tel:' + $props.About.formattedPhone
            }, _toDisplayString($props.About.phone), 9, _hoisted_8),
            _cache[0] || (_cache[0] = _createTextVNode(" ·"))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: 'mailto:' + $props.About.email,
        target: "_blank"
      }, _toDisplayString($props.About.email), 9, _hoisted_9)
    ])
  ]))
}