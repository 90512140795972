import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "resume-item d-flex flex-column flex-md-row justify-content-between" }
const _hoisted_2 = { class: "resume-content" }
const _hoisted_3 = { class: "mb-2 experience-name-date" }
const _hoisted_4 = { class: "experience-name" }
const _hoisted_5 = { class: "resume-date text-md-right experience-date" }
const _hoisted_6 = { class: "text-primary" }
const _hoisted_7 = { class: "mb-2" }
const _hoisted_8 = {
  key: 0,
  class: "job-header mb-1 mt-2"
}
const _hoisted_9 = { class: "job subheading" }
const _hoisted_10 = { class: "experience-date-minor" }
const _hoisted_11 = {
  key: 1,
  class: "m-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass(["bubble-major", { complete: $props.experience.status === 'complete', current: $props.experience.status === 'current' }])
          }, null, 2),
          _createElementVNode("div", null, _toDisplayString($props.experience.company), 1)
        ]),
        _createElementVNode("span", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString($props.experience.startDate) + " - " + _toDisplayString($props.experience.endDate), 1)
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.experience.roles, (o, index) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (o.role)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["bubble-minor", { complete: o.status === 'complete', current: o.status === 'current' }])
                  }, null, 2),
                  _createElementVNode("div", null, _toDisplayString(o.role), 1)
                ]),
                _createElementVNode("span", _hoisted_10, _toDisplayString(o.startDate) + " - " + _toDisplayString(o.endDate), 1),
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(o.bullets, (bullet, bulletIndex) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: 'ExperienceList' + index + 'Bullets' + bulletIndex
                    }, _toDisplayString(bullet), 1))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          (index !== $props.experience.roles.length - 1)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_11))
            : _createCommentVNode("", true)
        ]))
      }), 256))
    ])
  ]))
}