import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/img/profile.jpg'


const _hoisted_1 = {
  class: "resume-section p-3 p-lg-5 d-flex align-items-center",
  id: "about"
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { class: "mb-0 name" }
const _hoisted_4 = { class: "d-sm-none small-name" }
const _hoisted_5 = { class: "text-primary" }
const _hoisted_6 = {
  key: 0,
  class: "text-primary"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "d-sm-block d-none" }
const _hoisted_9 = { class: "text-primary" }
const _hoisted_10 = {
  key: 0,
  class: "text-primary"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "resume mt-4" }
const _hoisted_13 = { class: "p-2" }
const _hoisted_14 = { class: "p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AboutContact = _resolveComponent("AboutContact")!
  const _component_AboutSocialMedia = _resolveComponent("AboutSocialMedia")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "d-lg-none image-name" }, [
          _createElementVNode("img", {
            class: "img-fluid img-profile rounded-circle mx-auto mb-2 profile-pic",
            src: _imports_0,
            alt: "profile pic"
          })
        ], -1)),
        _createElementVNode("span", _hoisted_4, [
          _createTextVNode(_toDisplayString($props.About.firstName) + " ", 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString($props.About.lastName), 1),
          ($props.About.chineseLastName)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, " " + _toDisplayString($props.About.chineseLastName) + ">", 1))
            : _createCommentVNode("", true),
          ($props.About.chineseFirstName)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($props.About.chineseFirstName) + ">", 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("span", _hoisted_8, [
          _createTextVNode(_toDisplayString($props.About.firstName) + " ", 1),
          _createElementVNode("span", _hoisted_9, _toDisplayString($props.About.lastName), 1),
          ($props.About.chineseLastName)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, " " + _toDisplayString($props.About.chineseLastName), 1))
            : _createCommentVNode("", true),
          ($props.About.chineseFirstName)
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString($props.About.chineseFirstName), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_AboutContact, { About: $props.About }, null, 8, ["About"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.About.bio, (paragraph, index) => {
        return (_openBlock(), _createElementBlock("p", {
          class: "lead mb-3",
          key: 'Bio' + index
        }, _toDisplayString(paragraph), 1))
      }), 128)),
      ($props.About.socialmedia)
        ? (_openBlock(), _createBlock(_component_AboutSocialMedia, {
            key: 0,
            SocialMedia: $props.About.socialmedia
          }, null, 8, ["SocialMedia"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("span", _hoisted_13, [
          ($props.About.resume)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-primary btn-lg",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.resume()))
              }, _cache[3] || (_cache[3] = [
                _createElementVNode("i", { class: "fas fa-file-pdf" }, null, -1),
                _createTextVNode(" Resume ")
              ])))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("span", _hoisted_14, [
          ($props.About.cv)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-primary btn-lg",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.cv()))
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("i", { class: "fas fa-file-pdf" }, null, -1),
                _createTextVNode(" Curriculum Vitae (CV) ")
              ])))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}