import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "major mb-2" }
const _hoisted_2 = { class: "major-text mb-0" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "major mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, [
      ($props.Major.length === 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Major:"))
        : _createCommentVNode("", true),
      ($props.Major.length > 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Majors:"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("span", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.Major, (major, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: 'Major' + index,
          class: "mr-3 mb-3"
        }, [
          _createElementVNode("h4", _hoisted_5, _toDisplayString(major.title), 1)
        ]))
      }), 128))
    ])
  ]))
}