import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "social-icons mt-3"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = ["href"]
const _hoisted_8 = { key: 3 }
const _hoisted_9 = ["href"]
const _hoisted_10 = { key: 4 }
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.SocialMedia)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($props.SocialMedia.linkedin)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createElementVNode("a", {
                href: $props.SocialMedia.linkedin,
                target: "_blank",
                "data-toggle": "tooltip",
                "data-placement": "bottom",
                title: "LinkedIn"
              }, _cache[0] || (_cache[0] = [
                _createElementVNode("i", { class: "fab fa-linkedin-in" }, null, -1)
              ]), 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true),
        ($props.SocialMedia.facebook)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createElementVNode("a", {
                href: $props.SocialMedia.facebook,
                target: "_blank",
                "data-toggle": "tooltip",
                "data-placement": "bottom",
                title: "Facebook"
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("i", { class: "fab fa-facebook-f" }, null, -1)
              ]), 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true),
        ($props.SocialMedia.github)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
              _createElementVNode("a", {
                href: $props.SocialMedia.github,
                target: "_blank",
                "data-toggle": "tooltip",
                "data-placement": "bottom",
                title: "Github"
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("i", { class: "fab fa-github" }, null, -1)
              ]), 8, _hoisted_7)
            ]))
          : _createCommentVNode("", true),
        ($props.SocialMedia.twitter)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
              _createElementVNode("a", {
                href: $props.SocialMedia.twitter,
                target: "_blank",
                "data-toggle": "tooltip",
                "data-placement": "bottom",
                title: "Twitter"
              }, _cache[3] || (_cache[3] = [
                _createElementVNode("i", { class: "fab fa-twitter" }, null, -1)
              ]), 8, _hoisted_9)
            ]))
          : _createCommentVNode("", true),
        ($props.SocialMedia.wechat)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
              _createElementVNode("a", {
                href: $props.SocialMedia.wechat,
                target: "_blank",
                "data-toggle": "tooltip",
                "data-placement": "bottom",
                title: "Wechat"
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("i", { class: "fab fa-weixin" }, null, -1)
              ]), 8, _hoisted_11)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}