<template>
  <section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="contact">
    <div class="w-100">
      <h2 class="mb-2">Contact</h2>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdOBkx7WOg6rJZeraZO7R9NkbAw2unEApVq9zt8uta7ve2nJw/viewform?embedded=true" width="640" height="722" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  </section>
</template>

<script lang="ts">
import ContactComponent from './ContactComponent.vue'

export default {
  name: 'ContactComponent',
  props: {
    About: Object,
    ContactIndex: Number
  }
}
</script>

<style scoped lang="scss">
section.resume-section {
  min-height: 50vh;
  color: white;
  background-color: #42a5f5;
}

div.w-100 {
  padding-bottom: 3rem;
}

h2, h3 {
  color: white;
}

.btn {
  color: #42a5f5;
  background-color: #fafafa;
  width: 100px;
}

.btn:hover {
  color: #42a5f5;
  background-color: #ffffff;
}

.form-check-label {
  font-size: 1.25rem;
}

.subheading {
  margin: 0;
}

.form-check-input {
  margin-top: 10px;;
}

.form-check, .form-check input, .form-check label {
  cursor: pointer;
}

button[type=submit] {
  margin-top: 10px;
}
</style>
