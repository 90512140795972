<template>
  <h4 class="gpa">
    <div><span class="mr-5" v-if="GPA.cumulative">Cumulative GPA: {{GPA.cumulative}} / {{GPA.scale}}</span></div>
    <div><span v-if="GPA.major">Major GPA: {{GPA.major}} / {{GPA.scale}}</span></div>
  </h4>
</template>

<script lang="ts">
export default {
  name: 'EducationGPA',
  props: {
    GPA: Object
  }
}
</script>

<style scoped lang="scss">
.gpa {
  display: block;
  flex-wrap: wrap;
  font-weight: 500;
}

.gpa > span {
  display: inline-block;
  font-weight: 500;
}
</style>
