import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "courses" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["data-bs-target", "aria-controls"]
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = ["id", "aria-labelledby", "data-parent"]
const _hoisted_10 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, [
      ($props.Courses.length === 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Relevant Course:"))
        : _createCommentVNode("", true),
      ($props.Courses.length > 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Relevant Courses:"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: "accordion",
      id: 'AccordionCourses_' + $props.Degree_Index
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.Courses, (courses, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'Courses_' + $props.Degree_Index + '_'+ index,
          class: "card"
        }, [
          _createElementVNode("div", {
            class: "mb-0 card-header",
            id: $props.Degree_Index + '_heading_' + index
          }, [
            _createElementVNode("button", {
              class: "btn btn-link collapsed",
              type: "button",
              "data-bs-toggle": "collapse",
              "data-bs-target": '#collapse' + $props.Degree_Index + '_' + index,
              "aria-expanded": "false",
              "aria-controls": 'collapse' + $props.Degree_Index + '_' + index
            }, [
              _createElementVNode("h5", _hoisted_8, _toDisplayString(courses.code) + " - " + _toDisplayString(courses.title), 1)
            ], 8, _hoisted_7)
          ], 8, _hoisted_6),
          _createElementVNode("div", {
            id: 'collapse' + $props.Degree_Index + '_' + index,
            class: "collapse",
            "aria-labelledby": $props.Degree_Index + '_heading_' + index,
            "data-parent": '#AccordionCourses_' + $props.Degree_Index
          }, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(courses.description), 1)
          ], 8, _hoisted_9)
        ]))
      }), 128))
    ], 8, _hoisted_5)
  ]))
}