import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "resume-item d-flex flex-column flex-md-row justify-content-between mb-5" }
const _hoisted_2 = { class: "resume-content" }
const _hoisted_3 = { class: "mb-2 school-name-date" }
const _hoisted_4 = { class: "school-name" }
const _hoisted_5 = { class: "resume-date text-md-right school-date" }
const _hoisted_6 = { class: "text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EducationDegree = _resolveComponent("EducationDegree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass(["bubble-major", { complete: $props.education.status === 'complete', current: $props.education.status === 'current' }])
          }, null, 2),
          _createElementVNode("div", null, _toDisplayString($props.education.name), 1)
        ]),
        _createElementVNode("span", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString($props.education.startDate) + " - " + _toDisplayString($props.education.endDate), 1)
        ])
      ]),
      ($props.education.degrees)
        ? (_openBlock(), _createBlock(_component_EducationDegree, {
            key: 0,
            Degrees: $props.education.degrees
          }, null, 8, ["Degrees"]))
        : _createCommentVNode("", true)
    ])
  ]))
}