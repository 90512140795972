import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "subheading" }
const _hoisted_2 = { class: "list-inline" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString($props.skillset.title), 1),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.skillset.skills, (skill, idx) => {
        return (_openBlock(), _createElementBlock("li", {
          onClick: ($event: any) => ($options.toggleActivated(skill.index)),
          class: _normalizeClass([{active: $props.activeIndex === skill.index}, "list-inline-item"]),
          key: $props.skillset.title + 'Skill' + idx
        }, [
          _createElementVNode("i", {
            class: _normalizeClass([skill.icon, "icon"]),
            "data-toggle": "tooltip",
            "data-placement": "bottom",
            title: skill.title
          }, null, 10, _hoisted_4)
        ], 10, _hoisted_3))
      }), 128))
    ])
  ]))
}